<template>
    <div class="container-fluid home-main-container">
        <div class="col-md-12">
            <h1 class="home-main-title">
                <span>Find out if a local<br>business has stolen wages</span>
            </h1>
            <p class="home-sub-title pt-4">
                Documented compiled thousands of records of companies who committed wage theft in New York State from January, 2016 to April, 2021. Search for wage offenders below.
            </p>
        </div>
        <div class="col-md-12">
            <search-filter class="search-filter" />
        </div>
        <div class="col-md-12">
            <p class="home-footer-text">
                All data comes from the New York State Department<br>
                of Labor or the federal Department of Labor and was<br>
                obtained through a Freedom of Information lawsuit and open records.
            </p>
        </div>
    </div>
    <!-- <div class="home__container">
        <div class="home__title">New York wage dispute data.<br>How do you want to search?</div>
        <search-filter class="search-filter" />
        <div class="map">
            <heat-map :zoom="10.5" :center="[-73.9677614, 40.7574034]" />
        </div>
    </div> -->
</template>

<script>
import SearchFilter from '@/components/SearchFilter.vue'
// import HeatMap from '../components/HeatMap.vue'

export default {
    name: 'Home',
    components: {
        SearchFilter,
        // HeatMap,
    },
}
</script>

<style>

  .home-main-container{
    height: 100%;
    background:linear-gradient(
          rgba(0, 0, 0, 0.6),
          rgba(0, 0, 0, 0.6)
        ),
        /* bottom, image */
        url('../assets/background.jpg');
    /* background-image: url('../assets/background.jpg'); */
    background-color:#000;
    background-position: center;
    background-size: cover;
  }
  .home-main-title{
    font-family: 'Poppins', sans-serif;
    color: white;
    font-weight: 600;
    font-size: 3.6rem;
    margin: 150px 0px auto;
    text-align: left;
    line-height: 3.6rem;
  }
  .home-sub-title{
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    color:white;
    max-width: 31rem;
  }
  .home-footer-text{
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size:0.7rem;
    color:#707070;
    padding-top:5rem;
  }

@media (max-width: 576px) {
  .home-main-title{
    font-family: 'Poppins', sans-serif;
    color: white;
    font-weight: 600;
    font-size: 2rem;
    margin: 20px 0;
    text-align: left;
    line-height: 2rem;
  }

}
</style>
<style lang="scss" scoped>
@import '@/shared/styles/common';

.home {
    &__container {
        display: flex;
        flex-direction: column;
        align-items: left;
        height: 100%;
        background-image: url('../assets/background.jpg');
        background-position: center;
        background-size: cover;
    }
    &__title {
        // @include fat-font;
        font-family: 'Poppins', sans-serif;
        color: white;
        font-weight: 650;
        font-size: 3rem;
        margin: 62px 0;
        text-align: left;
    }
}
.search-filter {
    padding: 24px;
}
.map {
    position: relative;
    width: 708px;
    height: 400px;
    margin: 40px 0;
    border-radius: 4px;
    overflow: hidden;
}
</style>
